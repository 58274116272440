import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Divider,
  Grid,
  TextField,
  Box,
  Paper,
  Typography,
  Avatar,
  Chip,
  List,
  ListItem,
  CircularProgress,
  makeStyles,
  useMediaQuery,
  useTheme,
  FormHelperText
} from '@material-ui/core';
import {
  Check as CheckIcon,
  Clear as ClearIcon,
  History as HistoryIcon
} from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import {
  editClubProduct,
  uploadProductAndEventImages,
  updateProductAndEventImages,
  getAllClubProducts,
  descriptionTranslate,
  getSubscriptionActivity
} from '../../actions/productActions';
import Spinner from '../Spinner';
import UploadImage from '../Upload/ImageUpload';
import {
  findDescriptionField,
  getTargetLanguagesAndDescriptions,
  isDecimalNumber,
  formatDate
} from '../../utils/helper';

const useStyles = makeStyles((theme) => ({
  activitySection: {
    marginTop: theme.spacing(4)
  },
  activityList: {
    maxHeight: '150px',
    overflowY: 'auto',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    padding: 5
  },
  activityItem: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    padding: 6
  },
  activityIcon: {
    width: 20,
    height: 20,
    flexShrink: 0,
    padding: 2,
    borderRadius: "100%",
  },
  activityIconSubscribed: {
    color: theme.palette.success.main,
    backgroundColor: "#b5edb7",
  },
  activityIconUnsubscribed: {
    color: theme.palette.error.main,
    backgroundColor: "#ffd0cd",
  },
  activityText: {
    flex: 1
  },
  loadMoreContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2)
  },
  emptyState: {
    textAlign: 'center',
    padding: theme.spacing(4)
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(4)
  }
}));

const productBasicDetails = {
  id: '',
  title: '',
  price: '',
  expiryDate: '2022-12-31',
  crowdFunding: false,
  description: '',
  descriptionDe: '',
  descriptionFr: '',
  descriptionIt: '',
  productType: '',
};

const translatedDescription = {
  EN: '',
  DE: '',
  FR: '',
  IT: '',
};

const EditProductModal = (props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const { authUserData } = useSelector((state) => state.user);
  const { open, handleClose, product } = props;
  const theme = useTheme();
  const dispatch = useDispatch();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const SelectedLanguageValue = localStorage.getItem('language') || 'du';

  const [selectedProduct, setSelectedProduct] = useState(productBasicDetails);
  const [translatedText, setTranslatedText] = useState(translatedDescription);
  const [isLoading, setIsLoading] = useState(false);
  const [isTranslationCompleted, setTranslationComplete] = useState(false);
  const [productError, setProductError] = useState({});
  const [uploadedImg, setUploadedImg] = useState('');
  const [productImage, setProductImage] = useState([]);

  // Activity log states
  const [activities, setActivities] = useState([]);
  const [activityLoading, setActivityLoading] = useState(false);
  const [pageDetails, setPageDetails] = useState({
    pageId: 0,
    pageSize: 10,
    totalElements: 0
  });

  const isSubscription = selectedProduct?.productType?.toLowerCase() === 'subscription';

  const handleLoadMore = useCallback(() => {
    if (activityLoading || activities.length >= pageDetails.totalElements) return;

    setPageDetails(prev => ({
      ...prev,
      pageId: prev.pageId + 1
    }));
  }, [activities.length, pageDetails.totalElements, activityLoading]);

  // Update useEffect to watch for pageDetails changes
  useEffect(() => {
    if (open && isSubscription && product?.id) {
      loadActivities();
    }
  }, [open, product?.id, isSubscription, pageDetails.pageId]);

  const lastActivityElementRef = useCallback(node => {
    if (activityLoading) return;
    // Don't create observer if we've loaded all items
    if (activities.length >= pageDetails.totalElements) return;

    const observer = new IntersectionObserver(entries => {
      // Only load more if we haven't loaded everything yet
      if (entries[0].isIntersecting && activities.length < pageDetails.totalElements) {
        handleLoadMore();
      }
    }, {
      root: null,
      threshold: 0.1,
    });

    if (node) observer.observe(node);

    return () => {
      if (node) observer.disconnect();
    };
  }, [activityLoading, activities.length, pageDetails.totalElements, handleLoadMore]);

  useEffect(() => {
    if (product) {
      setSelectedProduct({
        id: product.id,
        title: product.title,
        price: product.price,
        expiryDate: product.expiryDate,
        image: product.image,
        description: product.description,
        descriptionDe: product.descriptionDe,
        descriptionFr: product.descriptionFr,
        descriptionIt: product.descriptionIt,
        crowdFunding: product.crowdFunding ? 'true' : 'false',
        productType: product.productType,
      });
      setUploadedImg(product.image);
      setTranslatedText(translatedDescription);
      if (product && !product.description) {
        setTranslationComplete(true);
      }

      // Reset pagination and activities when product changes
      setPageDetails({
        pageId: 0,
        pageSize: 10,
        totalElements: 0
      });
      setActivities([]);
    }
  }, [product]);

  useEffect(() => {
    if (open && isSubscription && product?.id) {
      setPageDetails(prev => ({ ...prev, pageId: 0 }));
      setActivities([]);
      loadActivities();
    }
  }, [open, product?.id, isSubscription]);

  const loadActivities = async () => {
    setActivityLoading(true);
    try {
      const data = await dispatch(getSubscriptionActivity(product.id, pageDetails.pageId, pageDetails.pageSize));
      if (data) {
        if (pageDetails.pageId === 0) {
          setActivities(data.content);
        } else {
          setActivities(prev => [...prev, ...data.content]);
        }
        setPageDetails(prev => ({
          ...prev,
          totalElements: data.totalElements
        }));
      }
    } catch (error) {
      console.error('Error loading activities:', error);
    }
    setActivityLoading(false);
  };

  const getActivityIcon = (type) => {
    switch (type.toLowerCase()) {
      case 'subscribed':
        return <CheckIcon fontSize="small" />;
      case 'unsubscribed':
        return <ClearIcon fontSize="small" />;
      default:
        return <HistoryIcon fontSize="small" />;
    }
  };

  const getChipClassName = (type) => {
    switch (type.toLowerCase()) {
      case 'subscribed':
        return classes.chipSubscribed;
      case 'unsubscribed':
        return classes.chipUnsubscribed;
      default:
        return classes.chipUpdate;
    }
  };

  useEffect(() => {
    i18n.changeLanguage(SelectedLanguageValue);
  }, [SelectedLanguageValue, i18n]);

  const handleProductDetailChange = (e) => {
    const { value, name } = e.target;
    setProductError({
      ...productError,
      [name]: '',
    });
    setSelectedProduct({
      ...selectedProduct,
      [name]: value,
    });
  };

  const isValidProduct = () => {
    let isValid = true;
    const basicFieldErrors = {};

    if (!selectedProduct.title) {
      basicFieldErrors.title = 'please enter title';
      isValid = false;
    }
    if (!selectedProduct.description) {
      basicFieldErrors.description = 'please enter description';
      isValid = false;
    }
    if (!selectedProduct.expiryDate) {
      basicFieldErrors.expiryDate = 'please enter expiryDate';
      isValid = false;
    }
    if (!selectedProduct.image && productImage.length === 0) {
      basicFieldErrors.productImage = 'please upload product image';
      isValid = false;
    }

    setProductError(basicFieldErrors);
    return isValid;
  };

  const saveProductCallBack = () => {
    const { clubId } = authUserData;
    dispatch(getAllClubProducts({ clubId }));
    setIsLoading(false);
    handleClose();
  };

  const handleSaveProduct = () => {
    if (isValidProduct()) {
      const newProductObj = {
        ...selectedProduct,
        crowdFunding: selectedProduct.crowdFunding === 'true',
      };
      setIsLoading(true);

      dispatch(
        editClubProduct(newProductObj, () => {
          if (productImage.length > 0) {
            const fileData = new FormData();
            fileData.append('file', productImage[0]);
            fileData.append('entity', 'product');
            fileData.append('column', 'image');
            fileData.append('id', selectedProduct.id);

            if (uploadedImg) {
              fileData.append('fileName', uploadedImg);
              dispatch(
                updateProductAndEventImages(fileData, () => {
                  saveProductCallBack();
                })
              );
            } else {
              dispatch(
                uploadProductAndEventImages(fileData, () => {
                  saveProductCallBack();
                })
              );
            }
          } else {
            saveProductCallBack();
          }
        })
      );
    }
  };

  const setNewImage = (file) => {
    const images = [];
    images.push(file);
    setProductImage(images);
  };

  const handleDescriptionChange = async (e) => {
    const descriptionText = e.target.value;
    setTranslatedText(translatedDescription);

    const targetLanguagesAndDescriptions = getTargetLanguagesAndDescriptions(
      translatedText,
      descriptionText,
      SelectedLanguageValue
    );

    const targetTranslationList = targetLanguagesAndDescriptions.targetLanguagesList || [];
    const translatedTextList = targetLanguagesAndDescriptions.descriptionList;

    for (const targetLang of targetTranslationList) {
      const response = await descriptionTranslate(descriptionText, targetLang);
      if (response) {
        translatedTextList[targetLang] = response;
      }
    }

    setSelectedProduct({
      ...selectedProduct,
      description: translatedTextList.EN,
      descriptionDe: translatedTextList.DE,
      descriptionFr: translatedTextList.FR,
      descriptionIt: translatedTextList.IT,
    });
    setTranslationComplete(true);
    setTranslatedText(translatedTextList);
  };

  const displayDescriptionField = () => {
    const fieldName = findDescriptionField(SelectedLanguageValue);
    return (
      <TextField
        id="standard-description-input"
        name={fieldName}
        label={t('product_description')}
        required
        fullWidth
        multiline
        rows={4}
        inputProps={{
          maxLength: 240,
        }}
        value={selectedProduct[fieldName]}
        error={productError[fieldName]}
        helperText={productError[fieldName]}
        InputLabelProps={{ shrink: true }}
        onChange={handleProductDetailChange}
        onBlur={handleDescriptionChange}
      />
    );
  };

  const renderActivityLog = () => {
    if (!isSubscription) return null;

    return (
      <Box className={classes.activitySection}>
        <Typography variant="h6" gutterBottom>
          {t('activity_log.title')}
        </Typography>

        {activityLoading && !activities.length ? (
          <div className={classes.spinner}>
            <CircularProgress />
          </div>
        ) : !activities.length ? (
          <List className={classes.activityList}>
            <div className={classes.emptyState}>
              <Typography color="textSecondary">
                {t('activity_log.no_activities')}
              </Typography>
            </div>
          </List>
        ) : (
          <>
            <List className={classes.activityList}>
              {activities.map((activity, index) => {
                const isSubscribed = activity.activityType.toLowerCase() === 'subscribed';
                const isLastElement = index === activities.length - 1;

                return (
                  <ListItem
                    key={index}
                    className={classes.activityItem}
                    ref={isLastElement ? lastActivityElementRef : null}
                  >
                    {isSubscribed ? (
                      <CheckIcon className={`${classes.activityIcon} ${classes.activityIconSubscribed}`} />
                    ) : (
                      <ClearIcon className={`${classes.activityIcon} ${classes.activityIconUnsubscribed}`} />
                    )}
                    <Typography className={classes.activityText}>
                      {formatDate(activity.date) != null ? formatDate(activity.date) : "missing_date"} {' - '}
                      <strong>{activity.userName}</strong>{' '}
                      {t(`activity_log.actions.${activity.activityType.toLowerCase()}`)}
                    </Typography>
                  </ListItem>
                );
              })}
              {activityLoading && (
                <ListItem className={classes.activityItem}>
                  <Box display="flex" justifyContent="center" width="100%">
                    <CircularProgress size={20} />
                  </Box>
                </ListItem>
              )}
            </List>
          </>
        )}
      </Box>
    );
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      aria-labelledby="responsive-dialog-title"
      fullWidth
      maxWidth="md"
      className="width-xl-60-modal create-product-modal"
    >
      <DialogTitle className="modal-title-center" id="responsive-dialog-title">
        {t('edit_product')}
      </DialogTitle>
      <Divider />

      <DialogContent>
        {isLoading ? (
          <Spinner />
        ) : (
          <Box p={2}>
            <form noValidate autoComplete="off">
              <Grid container spacing={3}>
                {/* Left Column - Image Upload */}
                <Grid item xs={12} md={4}>
                  <Paper elevation={0} style={{ padding: '8px' }}>
                    <Box mb={2}>
                      <FormHelperText style={{ color: 'red', marginBottom: '8px' }}>
                        {productError.productImage}
                      </FormHelperText>
                      <UploadImage
                        setImage={setNewImage}
                        removeImage={() => setProductImage([])}
                        alreadyUploadedImage={uploadedImg}
                      />
                    </Box>
                  </Paper>
                </Grid>

                {/* Right Column - Product Details */}
                <Grid item xs={12} md={8}>
                  <Paper elevation={0} style={{ padding: '16px' }}>
                    <Grid container spacing={3}>
                      {/* Title */}
                      <Grid item xs={12}>
                        <TextField
                          id="standard-name-input"
                          name="title"
                          label={t('product_title')}
                          required
                          fullWidth
                          variant="outlined"
                          value={selectedProduct.title}
                          error={productError.title}
                          helperText={productError.title}
                          InputLabelProps={{ shrink: true }}
                          onChange={handleProductDetailChange}
                          inputProps={{
                            maxLength: 25,
                          }}
                        />
                      </Grid>

                      {/* Price and Date */}
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="standard-price-input"
                          name="price"
                          label={t('product_price')}
                          required
                          fullWidth
                          variant="outlined"
                          value={selectedProduct.price}
                          error={productError.price}
                          helperText={productError.price}
                          onKeyPress={isDecimalNumber}
                          onChange={handleProductDetailChange}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="standard-date-input"
                          name="expiryDate"
                          type="date"
                          label={t('product_expiry_date')}
                          defaultValue="2021-12-31"
                          required
                          fullWidth
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          value={selectedProduct.expiryDate}
                          error={productError.expiryDate}
                          helperText={productError.expiryDate}
                          onChange={handleProductDetailChange}
                        />
                      </Grid>

                      {/* Description */}
                      <Grid item xs={12}>
                        <Box mt={2}>
                          {displayDescriptionField()}
                        </Box>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </form>

            {/* Activity Log Section */}
            {renderActivityLog()}
          </Box>
        )}
      </DialogContent>

      <Divider />
      <DialogActions className="action-buttons-modal">
        {!isLoading && (
          <>
            <Button
              className="cancel-button"
              variant="outlined"
              onClick={handleClose}
              size="large"
            >
              {t('cancel')}
            </Button>
            <Button
              disabled={!isTranslationCompleted || isLoading}
              className="btn-global-filled"
              variant="contained"
              onClick={handleSaveProduct}
              size="large"
            >
              {t('save')}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

EditProductModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  product: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string,
    price: PropTypes.number,
    expiryDate: PropTypes.string,
    image: PropTypes.string,
    crowdFunding: PropTypes.bool,
    descriptionDe: PropTypes.string,
    descriptionFr: PropTypes.string,
    descriptionIt: PropTypes.string,
    productType: PropTypes.string,
  }),
};

export default EditProductModal;