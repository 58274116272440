import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  filterMargin: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function DonationOverview(props) {
  const {
    graphData,
    pendingPayout,
    periodFilter,
    handleChangeTimeLine,
    isLoading
  } = props;
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const SelectedLanguageValue = localStorage.getItem('language');

  useEffect(() => {
    i18n.changeLanguage(SelectedLanguageValue);
  }, [SelectedLanguageValue, i18n]);

  return (
    <div className="tab-pane in active" id="tab0">
      <h4>{t('overview')}</h4>
      <div className={classes.filterMargin}>
        <Select
          value={periodFilter}
          onChange={handleChangeTimeLine}
          displayEmpty
          className={classes.selectEmpty}
          inputProps={{ 'aria-label': 'Without label' }}
          disabled={isLoading}
        >
          <MenuItem value="all">{t('total')}</MenuItem>
          <MenuItem value="year">{t('jahr')}</MenuItem>
          <MenuItem value="month">{t('monat')}</MenuItem>
          <MenuItem value="week">{t('woche')}</MenuItem>
          <MenuItem value="day">{t('tag')}</MenuItem>
        </Select>
      </div>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Card className={classes.root}>
            <CardContent>
              <Typography variant="h6" component="h3">
                {t('total_received')}
              </Typography>
              <Typography variant="body2" component="p">
                {graphData && graphData.totalAmount ? <>CHF {graphData.totalAmount}</> : '0.00'}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card className={classes.root}>
            <CardContent>
              <Typography variant="h6" component="h2">
                {t('number_of_donations')}
              </Typography>
              <Typography variant="body2" component="p">
                {graphData && graphData.noOfDonations ? graphData.noOfDonations : '0'}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Card className={classes.root}>
            <CardContent>
              <Typography variant="h6" component="h3">
                {t('next_payout')}
              </Typography>
              <Typography variant="body2" component="p">
                {pendingPayout && pendingPayout.amount ? <>CHF {pendingPayout.amount}</> : '0.00'}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card className={classes.root}>
            <CardContent>
              <Typography variant="h6" component="h2">
                {t('number_of_pending_donations')}
              </Typography>
              <Typography variant="body2" component="p">
                {pendingPayout && pendingPayout.noOfDonations ? pendingPayout.noOfDonations : '0'}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

DonationOverview.propTypes = {
  graphData: PropTypes.shape({
    totalAmount: PropTypes.number,
    noOfDonations: PropTypes.number,
  }),
  pendingPayout: PropTypes.shape({
    amount: PropTypes.number,
    noOfDonations: PropTypes.number,
  }),
  periodFilter: PropTypes.string.isRequired,
  handleChangeTimeLine: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

DonationOverview.defaultProps = {
  graphData: {},
  pendingPayout: {},
  isLoading: false,
};