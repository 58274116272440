import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import CreateProductForm from '../Forms/CreateProductForm';
import Spinner from '../Spinner';
import UploadImage from '../Upload/ImageUpload';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(3),
  },
  formSection: {
    padding: theme.spacing(2),
  },
  actionButtons: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(2),
  },
  loadingButton: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiCircularProgress-root': {
      marginRight: theme.spacing(1),
    },
  }
}));

export default function CreateProductModal(props) {
  const {
    open,
    handleOpen,
    product,
    errors,
    handleChange,
    handleProductType,
    handleProductCreate,
    isLoading,
    setProductImage,
    removeProductImage,
    isFreeSponsorModalOpen,
    handleProductDescChange,
    handleTranslateOptions,
    isTranslating
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { t, i18n } = useTranslation();
  const selectedLanguage = localStorage.getItem('language') || 'du';

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      aria-labelledby="responsive-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle className="modal-title-center" id="responsive-dialog-title">
        {t('create_product')}
      </DialogTitle>
      <Divider />

      <DialogContent className={classes.dialogContent}>
        {isLoading ? (
          <Spinner />
        ) : (
          <Box>
            <Grid container spacing={3}>
              {/* Left Column - Image Upload */}
              <Grid item xs={12} md={4}>
                <Paper elevation={0} className={classes.formSection}>
                  <Box mb={2}>
                    <FormHelperText style={{ color: 'red', marginBottom: '8px' }}>
                      {errors.productImage}
                    </FormHelperText>
                    <UploadImage
                      setImage={setProductImage}
                      removeImage={removeProductImage}
                      alreadyUploadedImage=""
                    />
                  </Box>
                </Paper>
              </Grid>

              {/* Right Column - Product Form */}
              <Grid item xs={12} md={8}>
                <Paper elevation={0} className={classes.formSection}>
                  <CreateProductForm
                    product={product}
                    errors={errors}
                    handleChange={handleChange}
                    isFreeSponsorModalOpen={isFreeSponsorModalOpen}
                    handleProductDescChange={handleProductDescChange}
                    handleTranslateOptions={handleTranslateOptions}
                    handleProductType={handleProductType}
                    isTranslating={isTranslating}
                  />
                </Paper>
              </Grid>
            </Grid>
          </Box>
        )}
      </DialogContent>

      <Divider />
      <DialogActions>
        <Box className={classes.actionButtons}>
          {!isLoading && (
            <>
              <Button
                className="cancel-button"
                onClick={handleOpen}
                variant="outlined"
                size="large"
              >
                {t('cancel')}
              </Button>
              <Button
                disabled={!product.description || isLoading || isTranslating}
                className="btn-global-filled"
                onClick={handleProductCreate}
                variant="contained"
                size="large"
              >
                {isTranslating ? (
                  <span className={classes.loadingButton}>
                    <CircularProgress size={16} color="inherit" />
                    {t('automatically_translating')}
                  </span>
                ) : (
                  t('create')
                )}
              </Button>
            </>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
}

CreateProductModal.propTypes = {
  open: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  isTranslating: PropTypes.bool,
  handleOpen: PropTypes.func.isRequired,
  product: PropTypes.shape({
    description: PropTypes.string,
  }),
  errors: PropTypes.shape({
    productImage: PropTypes.string,
  }),
  handleChange: PropTypes.func,
  handleProductCreate: PropTypes.func.isRequired,
  setProductImage: PropTypes.func.isRequired,
  removeProductImage: PropTypes.func.isRequired,
  isFreeSponsorModalOpen: PropTypes.bool.isRequired,
  handleProductDescChange: PropTypes.func.isRequired,
  handleTranslateOptions: PropTypes.func.isRequired,
  handleProductType: PropTypes.func,
};

CreateProductModal.defaultProps = {
  product: {},
  errors: {},
  handleChange: null,
  handleProductType: null,
  isLoading: false,
  isTranslating: false,
};