import React, { Fragment, useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/Delete';
import FormHelperText from '@material-ui/core/FormHelperText';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import PropTypes from 'prop-types';
import { imageBaseUrl, dataURItoBlob } from '../../utils/helper';
import ImageCrop from '../Modals/CropImageModal';

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    position: 'relative',
    width: '100%',
    minHeight: 300,
    border: `2px dashed ${theme.palette.grey[300]}`,
    borderRadius: theme.spacing(1),
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.grey[50],
    '&:hover': {
      borderColor: "#a2a2a2",
      cursor: 'pointer',
    },
  },
  imagePreview: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  uploadedImage: {
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain',
  },
  deleteIcon: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
    borderRadius: '50%',
    padding: theme.spacing(0.5),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  uploadIcon: {
    fontSize: 48,
    color: theme.palette.grey[400],
  },
  uploadText: {
    color: theme.palette.grey[600],
    marginTop: theme.spacing(1),
  },
  errorText: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(1),
  }
}));

const MAX_SIZE = 1024 * 1024 * 2;

export default function ImageUpload(props) {
  const { setImage, removeImage, alreadyUploadedImage } = props;
  const classes = useStyles();
  const [file, setFile] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [uploadError, setUploadError] = useState('');
  const [originalFile, setOriginalFile] = useState('');
  const [openCropModal, setCropModal] = useState(false);
  const [selectedImageURL, setSelectedImageURL] = useState('');

  const handleImageChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const filesCount = event.target.files.length;
      if (filesCount > 1) {
        event.preventDefault();
        setUploadError('Cannot upload more than one image');
      } else if (filesCount === 1) {
        const newFile = event.target.files[0];
        if (newFile.size > MAX_SIZE) {
          setUploadError('Please upload an image less than 2MB in size');
        } else {
          const dataUri = URL.createObjectURL(newFile);
          setCropModal(true);
          setOriginalFile(newFile);
          setSelectedImageURL(dataUri);
          setUploadError('');
        }
      }
    }
  };

  useEffect(() => {
    if (alreadyUploadedImage) {
      const url = `${imageBaseUrl}/${alreadyUploadedImage}`;
      setImageUrl(url);
    }
  }, [alreadyUploadedImage]);

  const deleteImage = () => {
    removeImage();
    setFile('');
    setImageUrl('');
  };

  const isDisabled = file || imageUrl;

  const handleCloseCropModal = () => {
    setCropModal(false);
  };

  const setCroppedImage = (dataURL) => {
    setFile(dataURL);
    const blob = dataURItoBlob(dataURL);
    const resultFile = new File([blob], originalFile.name, { type: originalFile.type });
    setImage(resultFile);
  };

  return (
    <Fragment>
      <Box mb={2}>
        <div className={classes.imageContainer}>
          <input
            disabled={isDisabled}
            accept="image/png, image/jpeg"
            style={{ display: 'none' }}
            id="upload-photo"
            type="file"
            onChange={handleImageChange}
          />

          {(file || imageUrl) ? (
            <div className={classes.imagePreview}>
              <img
                src={file || imageUrl}
                alt="Product"
                className={classes.uploadedImage}
              />
              <DeleteIcon
                className={classes.deleteIcon}
                onClick={deleteImage}
              />
            </div>
          ) : (
            <label htmlFor="upload-photo" style={{ width: '100%', height: '100%', cursor: 'pointer' }}>
              <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100%">
                <AddPhotoAlternateIcon className={classes.uploadIcon} />
              </Box>
            </label>
          )}
        </div>
      </Box>

      {uploadError && (
        <FormHelperText className={classes.errorText}>
          {uploadError}
        </FormHelperText>
      )}

      <ImageCrop
        open={openCropModal}
        handleClose={handleCloseCropModal}
        setCroppedImage={setCroppedImage}
        selectedImageURL={selectedImageURL}
        imageDimesions={{ unit: 'px', width: 300, aspectRatio: 4 / 3 }}
      />
    </Fragment>
  );
}

ImageUpload.propTypes = {
  setImage: PropTypes.func.isRequired,
  removeImage: PropTypes.func.isRequired,
  alreadyUploadedImage: PropTypes.string.isRequired,
};